import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/swap',
    // showItemsOnMobile: false,
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    icon: 'Earn',
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Pools'),
        href: '/pools',
      },
    ],
  },

  {
    label: t('Services'),
    href: '/',
    icon: 'Info',
    hideSubNav:true,
    items: [
      {
        label: t('Marketplace'),
        href: 'https://bitdealz.com',
        type: DropdownMenuItemType.EXTERNAL_LINK,

      },
      {
        label: t('DeFi IDO'),
        href: 'https://bitdealz.finance',
        type: DropdownMenuItemType.EXTERNAL_LINK,

      },
      {
        label: t('DeFi Games'),
        href: 'https://bitdealz.games',
        type: DropdownMenuItemType.EXTERNAL_LINK,

      },
      {
        label: t('DeFi Wallet'),
        href: '/',
      },
      {
        label: t('DeFi Escrow'),
        href: '/',
      },
      {
        label: t('NFT Marketplace'),
        href: '/',
      },
      {
        label: t('CoinMarket Trends '),
        href: 'https://bitdealz.com/coinmarket',
        type: DropdownMenuItemType.EXTERNAL_LINK,

      },
    ],
  },


 
  
  
  // {
  //   label: t('Ido'),
  //   href: '/ido',
  //   icon: 'Ido',
  //   items: [

  //   ],
  // },

]

export default config
