import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()
console.log(serializedTokens,"serializedTokens")
const pools: SerializedPoolConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: serializedTokens.sparco,
  //   earningToken: serializedTokens.sparco,
  //   contractAddress: {
  //     97: '0xc864FBF36F7C32303111a2693841dFf0B222c3CF',
  //     56: '0x7f3d65e18f5d26ed1b27a2c99a3964c571950f4f',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.03805175038',
  //   sortOrder: 0,
  //   isFinished: false,
  // },
  {
    sousId: 1,
    stakingToken: serializedTokens.kings,
    earningToken: serializedTokens.kings,
    contractAddress: {
      97: '0x3BeF888beB47DB1F7b52479D270dBe712AA47d8F',
      56: '0x7f3d65e18f5d26ed1b27a2c99a3964c571950f4f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.03805175038',
    sortOrder: 0,
    isFinished: false,
  }

]

export default pools
